declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const getQuoteButtonClick = (
  event: string,
  id: string,
  value: string,
  clickId: string,
  adId: string,
  bid: number,
  position: number,
  adProvider: string,
  carrier: string,
  requestId: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    id: id,
    clickId,
    ad_id: adId,
    event: event,
    value: value,
    ad_bid_price: bid,
    position: position,
    ad_provider: carrier,
  });

  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "USD",
      transaction_id: id,
      value: bid,
      items: [
        {
          item_id: `${carrier}-${adId.toString()}`,
          item_name: carrier,
          index: position,
          price: bid,
        },
      ],
    },
  });
};

export const dataLayerHandler = async (
  event: string,
  id: string,
  value?: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    id: id,
    event: event,
    value: value,
  });
};

export const getSessionId = () => {
  let session_id = "";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const key = cookies[i].trim().split("=")[0];
    const val = cookies[i].trim().split("=")[1];
    if (key === "_ga_K4D1Z9CZBS") {
      session_id = val.split(".")[2];
    }
  }
  return session_id;
};

export const getClientId = () => {
  let client_id = "";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const key = cookies[i].trim().split("=")[0];
    const val = cookies[i].trim().split("=")[1];
    if (key === "_ga") {
      client_id = val.split(".")[2] + "." + val.split(".")[3];
    }
  }
  return client_id;
};

export const stateAbbrevMapping: any = {
  AZ: "Arizona",
  AL: "Alabama",
  AK: "Alaska",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const generateStaticPaths = () => {
  const paths = [
    { params: { id: "cat-insurance-plans" } },
    { params: { id: "dog-insurance-cost" } },
    { params: { id: "cheap-dog-insurance" } },
    { params: { id: "best-pet-health-insurance" } },
    { params: { id: "compare-cat-insurance" } },
    { params: { id: "cheapest-pet-insurance" } },
    { params: { id: "affordable-pet-insurance" } },
    { params: { id: "best-rated-pet-insurance" } },
    { params: { id: "compare-dog-insurance" } },
    { params: { id: "best-multiple-pet-insurance" } },
    { params: { id: "kitten-insurance" } },
    { params: { id: "multi-pet-insurance" } },
    { params: { id: "cat-insurance-cost" } },
    { params: { id: "wellness-pet-insurance" } },
    { params: { id: "cheap-pet-insurance" } },
    { params: { id: "cat-health-insurance" } },
    { params: { id: "pet-care-insurance" } },
    { params: { id: "pet-insurance-prices" } },
    { params: { id: "dog-life-insurance" } },
    { params: { id: "pet-health-plans" } },
    { params: { id: "dog-medical-insurance" } },
    { params: { id: "vet-insurance" } },
    { params: { id: "pet-insurance-online" } },
    { params: { id: "insure-my-pet" } },
    { params: { id: "full-coverage-pet-insurance" } },
    { params: { id: "pet-accident-insurance" } },
    { params: { id: "types-of-pet-insurance" } },
    { params: { id: "top-dog-insurance" } },
    { params: { id: "pet-life-insurance" } },
    { params: { id: "best-dog-insurance" } },
    { params: { id: "dog-insurance-comparison" } },
    { params: { id: "dog-health-insurance" } },
    { params: { id: "dog-pet-insurance" } },
    { params: { id: "dog-insurance" } },
    { params: { id: "best-pet-insurance-for-dogs" } },
    { params: { id: "best-cat-insurance" } },
    { params: { id: "cat-pet-insurance" } },
    { params: { id: "cat-insurance" } },
    { params: { id: "best-pet-insurance" } },
    { params: { id: "compare-pet-insurance" } },
    { params: { id: "pet-wellness-plans" } },
    { params: { id: "best-pet-insurance-plans" } },
    { params: { id: "pet-plan-insurance" } },
    { params: { id: "pet-insurance" } },
    { params: { id: "pet-insurance-companies" } },
    { params: { id: "dog-insurance-plans" } },
    { params: { id: "pet-insurance-plans-for-dogs" } },
    { params: { id: "pet-insurance-quotes" } },
    { params: { id: "puppy-insurance" } },
    { params: { id: "pet-insurance-cover" } },
    { params: { id: "top-pet-insurance" } },
    { params: { id: "animal-insurance" } },
    { params: { id: "pet-insurance-cost" } },
    { params: { id: "default" } },
  ];

  return { paths: paths, fallback: true };
};

export const adGroupIdMapping: { [key: string]: string } = {
  "154636331751": "affordable-pet-insurance",
  "154636331911": "animal-insurance",
  "154636331951": "best-cat-insurance",
  "154636331991": "best-dog-insurance",
  "154636332151": "best-multiple-pet-insurance",
  "154636332191": "best-pet-health-insurance",
  "154636332231": "best-pet-insurance",
  "154636332391": "best-pet-insurance-for-dogs",
  "154636332431": "best-pet-insurance-plans",
  "154636332471": "best-rated-pet-insurance",
  "154636332631": "cat-health-insurance",
  "154636332671": "cat-insurance",
  "154636332711": "cat-insurance-cost",
  "154636332871": "cat-insurance-plans",
  "154636332911": "cat-pet-insurance",
  "154636332951": "cheap-dog-insurance",
  "154636333111": "cheap-pet-insurance",
  "154636333151": "cheapest-pet-insurance",
  "154636333191": "compare-cat-insurance",
  "154636333351": "compare-dog-insurance",
  "154636333391": "compare-pet-insurance",
  "154636333431": "dog-health-insurance",
  "154636333591": "dog-insurance",
  "154636333631": "dog-insurance-comparison",
  "154636333671": "dog-insurance-cost",
  "154636333831": "dog-insurance-plans",
  "154636333871": "dog-life-insurance",
  "154636333911": "dog-medical-insurance",
  "154636334071": "dog-pet-insurance",
  "154636334111": "full-coverage-pet-insurance",
  "154636334151": "insure-my-pet",
  "154636334311": "kitten-insurance",
  "154636334351": "multi-pet-insurance",
  "154636334391": "pet-accident-insurance",
  "154636334551": "pet-care-insurance",
  "154636334591": "pet-health-plans",
  "154636334631": "pet-insurance",
  "154636334791": "pet-insurance-companies",
  "154636334831": "pet-insurance-cover",
  "154636334871": "pet-insurance-online",
  "154636335031": "pet-insurance-plans-for-dogs",
  "154636335071": "pet-insurance-prices",
  "154636335111": "pet-insurance-quotes",
  "154636335271": "pet-life-insurance",
  "154636335311": "pet-plan-insurance",
  "154636335351": "pet-wellness-plans",
  "154636335511": "puppy-insurance",
  "154636335551": "top-dog-insurance",
  "154636335591": "top-pet-insurance",
  "154636335751": "types-of-pet-insurance",
  "154636335791": "vet-insurance",
  "154636335831": "wellness-pet-insurance",
};

export const adGroupNameMapping = {
  default: "123",
  "affordable-pet-insurance": "154636331751",
  "animal-insurance": "154636331911",
  "best-cat-insurance": "154636331951",
  "best-dog-insurance": "154636331991",
  "best-multiple-pet-insurance": "154636332151",
  "best-pet-health-insurance": "154636332191",
  "best-pet-insurance": "154636332231",
  "best-pet-insurance-for-dogs": "154636332391",
  "best-pet-insurance-plans": "154636332431",
  "best-rated-pet-insurance": "154636332471",
  "cat-health-insurance": "154636332631",
  "cat-insurance": "154636332671",
  "cat-insurance-cost": "154636332711",
  "cat-insurance-plans": "154636332871",
  "cat-pet-insurance": "154636332911",
  "cheap-dog-insurance": "154636332951",
  "cheap-pet-insurance": "154636333111",
  "cheapest-pet-insurance": "154636333151",
  "compare-cat-insurance": "154636333191",
  "compare-dog-insurance": "154636333351",
  "compare-pet-insurance": "154636333391",
  "dog-health-insurance": "154636333431",
  "dog-insurance": "154636333591",
  "dog-insurance-comparison": "154636333631",
  "dog-insurance-cost": "154636333671",
  "dog-insurance-plans": "154636333831",
  "dog-life-insurance": "154636333871",
  "dog-medical-insurance": "154636333911",
  "dog-pet-insurance": "154636334071",
  "full-coverage-pet-insurance": "154636334111",
  "insure-my-pet": "154636334151",
  "kitten-insurance": "154636334311",
  "multi-pet-insurance": "154636334351",
  "pet-accident-insurance": "154636334391",
  "pet-care-insurance": "154636334551",
  "pet-health-plans": "154636334591",
  "pet-insurance": "154636334631",
  "pet-insurance-companies": "154636334791",
  "pet-insurance-cover": "154636334831",
  "pet-insurance-online": "154636334871",
  "pet-insurance-plans-for-dogs": "154636335031",
  "pet-insurance-prices": "154636335071",
  "pet-insurance-quotes": "154636335111",
  "pet-life-insurance": "154636335271",
  "pet-plan-insurance": "154636335311",
  "pet-wellness-plans": "154636335351",
  "puppy-insurance": "154636335511",
  "top-dog-insurance": "154636335551",
  "top-pet-insurance": "154636335591",
  "types-of-pet-insurance": "154636335751",
  "vet-insurance": "154636335791",
  "wellness-pet-insurance": "154636335831",
};

export const stateCodeMapping = {
  AZ: "Arizona",
  AL: "Alabama",
  AK: "Alaska",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
