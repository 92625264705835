// import fs from 'fs';
// import { useEffect } from 'react';
// import HelmetLayout from '@/helmet/index';
// import { useSessionStorage } from 'react-use';
// import { HomePage } from '@/common/components/Home';

// export default function Home({ dynamicData }: any) {
//   function isObjEmpty(obj = {}) {
//     return Object!.keys(obj).length === 0;
//   }
//   const [localAds, setSessionValue] = useSessionStorage('ads', {});
//   useEffect(() => {
//     if (!isObjEmpty(localAds)) {
//       setSessionValue({});
//     }
//   }, []);

//   return (
//     <HelmetLayout
//       title={dynamicData.landing_page_SEO_title}
//       description={dynamicData.landing_page_SEO_description}
//     >
//       <HomePage dynamicData={dynamicData} />
//     </HelmetLayout>
//   );
// }

// export async function getStaticProps() {
//   const response = await fs.promises.readFile(
//     `public/dynamic/${process.env.NEXT_PUBLIC_PROJECT}/data.json`
//   );
//   const data = JSON.parse(response.toString());
//   return {
//     props: {
//       dynamicData: data,
//     },
//   };
// }

import { useEffect } from "react";
import { useRouter } from "next/router";
import { adGroupIdMapping } from "@/utils/index";

const Home = () => {
  const router = useRouter();

  const urlHashMap = new Map();
  const routhAsPath = router.asPath.split("?")[1];
  const urlParams = new URLSearchParams(routhAsPath);
  urlParams?.forEach((value, key) => {
    urlHashMap.set(key, value);
  });
  const urlObject: any = {};
  urlParams?.forEach((value, key) => {
    urlObject[key] = value;
  });

  let urlPath = "";
  if (!adGroupIdMapping[urlObject["adgroupid"]]) {
    urlPath = "quotes/default?";
  }
  else {
    urlPath = "quotes/" + adGroupIdMapping[urlObject["adgroupid"]] + "?";
  }
  urlParams?.forEach((value, key) => {
    urlPath += key + "=" + value + "&";
  });

  useEffect(() => {
    router.push(
      urlPath,
    );
  }, []);
};

export default Home;